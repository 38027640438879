<template>
  <div v-if="quote && quote[policy_type]">
    <v-card flat tile v-if="title">      
      <v-img
        v-if="show_ribbon"
        :aspect-ratio="1"
        contain
        class="ribbon-no-fold"
        :src="ribbon_no_fold"
        :lazy-src="ribbon_no_fold"
      >
        <v-layout
          slot="placeholder"
          fill-height
          align-center
          justify-center
          ma-0
        >
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
      </v-img>

      <v-card-title class="align-center justify-center primary py-1">
        <p class="graphik-medium normalcase font-20 white--text mb-0">{{ title }}</p>
      </v-card-title>

      <v-card-text class="light-background" :class="{'pa-1': $vuetify.breakpoint.xsOnly}">
        <v-layout row wrap align-center justify-center>
          <v-flex xs6>
            <v-img
              v-if="quote.company"
              :aspect-ratio="16/9"
              contain
              :src="image"
              :lazy-src="image"
              height="100px"
            >
              <v-layout
                slot="placeholder"
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-layout>
            </v-img>

            <template v-else>
              <vue-content-loading :width="300" :height="95">
                <rect x="30" y="20" rx="4" ry="4" width="80%" height="50" />
              </vue-content-loading>
            </template>
          </v-flex>

          <v-flex xs6 text-xs-center>
            <template v-if="quote && quote[policy_type][payment_type].prices && !quote[policy_type][payment_type].error">
              <p
                class="graphik-medium dark-grey--text mb-0"
                :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}"
              >{{ getPaymentText() }}</p>

              <p
                class="graphik-medium dark-grey--text mb-0"
                :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17': $vuetify.breakpoint.xsOnly}"
              >{{ quote[policy_type][payment_type].prices.next ? quote[policy_type][payment_type].prices.next : quote[policy_type][payment_type].prices.total | price }}</p>

              <p
                class="mb-0"
                :class="{'font-12': $vuetify.breakpoint.smAndUp, 'font-10': $vuetify.breakpoint.xsOnly}"
                v-if="payment_type !== '1'"
              >{{ $ml.get('quote_result_first_payment') }} <br v-if="$vuetify.breakpoint.xsOnly"> {{ quote[policy_type][payment_type].prices.first | price }}</p>
            </template>

            <template v-else-if="quote && quote[policy_type][payment_type].error">
              <p
                :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}"
                class="graphik-medium dark-grey--text mb-0"
              >{{ $ml.get('quote_results_to_quote') }}</p>

              <p
                class="graphik-medium dark-grey--text mb-0"
                :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17': $vuetify.breakpoint.xsOnly}"
              ><v-icon class="secondary--text mr-2 line-height-25 rotate-180 font-20">fas fa-phone</v-icon> {{ $ml.get('general_phone_number') }}</p>

              <p
                class="mb-0"
                :class="{'font-12': $vuetify.breakpoint.smAndUp, 'font-10': $vuetify.breakpoint.xsOnly}"
              >{{ $ml.get('general_schedule') }}</p>
            </template>

            <template v-else>
              <vue-content-loading :width="300" :height="95">
                <rect x="60" y="0" rx="4" ry="4" width="60%" height="15" />

                <rect x="15" y="26" rx="4" ry="4" width="90%" height="25" />

                <rect x="15" y="60" rx="4" ry="4" width="90%" height="10" />
              </vue-content-loading>
            </template>
          </v-flex>

          <template v-if="quote && !quote[policy_type][payment_type].error">
            <v-flex xs6 :px-3="$vuetify.breakpoint.smAndUp" :px-2="$vuetify.breakpoint.xsOnly">
              <v-btn 
                color="primary"
                dark depressed block
                :loading="g_isLoading || !quote[policy_type][payment_type].prices"
                class="normalcase graphik-medium mx-0 border-radius-5"
                :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                :disabled="g_isLoading"
                @click.stop="open(quote)"
              >{{ $ml.get('general_buy_card') }}</v-btn>
            </v-flex>

            <v-flex xs6 :px-3="$vuetify.breakpoint.smAndUp" :px-2="$vuetify.breakpoint.xsOnly">
              <v-btn 
                color="primary"
                dark outline block
                :loading="g_isLoading || !quote[policy_type][payment_type].prices"
                class="normalcase graphik-medium mx-0 border-radius-5"
                :disabled="g_isLoading"
                :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                @click.stop="open(quote, true)"
              >{{ $ml.get('general_buy_cash') }}</v-btn>
            </v-flex>
          </template>

          <template v-else>
            <v-flex xs6 :px-3="$vuetify.breakpoint.smAndUp" :px-2="$vuetify.breakpoint.xsOnly">
              <v-btn 
                color="primary"
                outline block
                class="normalcase graphik-medium mx-0 border-radius-5 white--text"
                :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                :disabled="g_isLoading"
                @click.stop="callNow()"
                :loading="!quote"
              >{{ $ml.get('general_call_now') }}</v-btn>
            </v-flex>

            <v-flex xs6 :px-3="$vuetify.breakpoint.smAndUp" :px-2="$vuetify.breakpoint.xsOnly">
              <v-btn 
                color="primary"
                outline block
                class="normalcase graphik-medium mx-0 border-radius-5 white--text"
                :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                :disabled="g_isLoading"
                @click.stop="sendWhatsapp(quote)"
                :loading="!quote"
              >{{ $ml.get('general_send_whatsapp') }}</v-btn>
            </v-flex>
          </template>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-expansion-panel v-else class="hidden-shadow my-3" v-model="panel">
      <v-expansion-panel-content>
        <template v-slot:header>
          <v-layout row wrap>
            <v-flex xs12>        
              <v-card flat height="100%" class="light-background">
                <v-img
                  v-if="show_ribbon"
                  :aspect-ratio="1"
                  contain
                  class="ribbon"
                  :src="ribbon"
                  :lazy-src="ribbon"
                >
                  <v-layout
                    slot="placeholder"
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-layout>
                </v-img>

                <v-card-text :class="{'py-0 px-1': $vuetify.breakpoint.smAndDown}">
                  <v-layout row wrap align-center :class="{'min-height-150': $vuetify.breakpoint.mdAndUp}">
                    <v-flex xs6 md3 d-flex>
                      <v-img
                        v-if="quote.company"
                        :aspect-ratio="16/9"
                        contain
                        :src="image"
                        :lazy-src="image"
                        :height="$vuetify.breakpoint.mdAndUp ? '150px' : '100px'"
                      >
                        <v-layout
                          slot="placeholder"
                          fill-height
                          align-center
                          justify-center
                          ma-0
                        >
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-layout>
                      </v-img>

                      <template v-else>
                        <vue-content-loading :width="300" :height="155">
                          <rect x="30" y="20" rx="4" ry="4" width="80%" height="100" />
                        </vue-content-loading>
                      </template>
                    </v-flex>

                    <v-flex xs6 md6 :text-xs-center="$vuetify.breakpoint.smAndDown">
                      <template v-if="quote && quote[policy_type][payment_type].prices && !quote[policy_type][payment_type].error">
                        <p
                          class="graphik-medium dark-grey--text mb-0"
                          :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}"
                        >{{ getPaymentText() }}</p>

                        <p
                          class="graphik-medium dark-grey--text mb-0"
                          :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17': $vuetify.breakpoint.xsOnly}"
                        >{{ quote[policy_type][payment_type].prices.next ? quote[policy_type][payment_type].prices.next : quote[policy_type][payment_type].prices.total | price }}</p>

                        <p
                          class="graphik-light mb-0" v-if="payment_type !== '1'"
                          :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-10': $vuetify.breakpoint.xsOnly}"
                        >{{ $ml.get('quote_result_first_payment') }} {{ quote[policy_type][payment_type].prices.first | price }}</p>
                      </template>

                      <template v-else-if="quote && quote[policy_type][payment_type].error">
                        <p
                          class="graphik-medium dark-grey--text mb-0"
                          :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}"
                        >{{ $ml.get('quote_results_to_quote') }}</p>

                        <p
                          class="graphik-medium dark-grey--text mb-0"
                          :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17': $vuetify.breakpoint.xsOnly}"
                        ><v-icon class="secondary--text mr-2 line-height-25 rotate-180 font-20">fas fa-phone</v-icon> {{ $ml.get('general_phone_number') }}</p>

                        <p
                          class="graphik-light mb-0"
                          :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-10': $vuetify.breakpoint.xsOnly}"
                        >{{ $ml.get('general_schedule') }}</p>
                      </template>

                      <template v-else>
                        <vue-content-loading :width="300" :height="71">
                          <rect x="0" y="0" rx="4" ry="4" width="60%" height="15" />

                          <rect x="0" y="26" rx="4" ry="4" width="90%" height="25" />

                          <rect x="0" y="60" rx="4" ry="4" width="90%" height="10" />
                        </vue-content-loading>
                      </template>
                    </v-flex>

                    <v-flex xs12 md3 text-xs-center v-if="!quote[policy_type][payment_type].error">
                      <v-layout row wrap>
                        <v-flex xs6 md12 :px-2="$vuetify.breakpoint.smAndDown">
                          <v-btn 
                            color="primary"
                            depressed block
                            :loading="g_isLoading || !quote[policy_type][payment_type].coverages"
                            class="normalcase graphik-medium mx-0 border-radius-5 white--text"
                            :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                            :disabled="g_isLoading"
                            @click.stop="open(quote)"
                          >{{ $ml.get('general_buy_card') }}</v-btn>
                        </v-flex>

                        <v-flex xs6 md12 :px-2="$vuetify.breakpoint.smAndDown">
                          <v-btn 
                            color="primary"
                            outline block
                            :loading="g_isLoading || !quote[policy_type][payment_type].coverages"
                            class="normalcase graphik-medium mx-0 border-radius-5 white--text"
                            :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                            :disabled="g_isLoading"
                            @click.stop="open(quote, true)"
                          >{{ $ml.get('general_buy_cash') }}</v-btn>
                        </v-flex>
                      </v-layout>

                      <v-btn
                        flat small block
                        color="dark-grey"
                        :ripple="false"
                        :disabled="!quote[policy_type][payment_type].coverages"
                        class="normalcase graphik-medium mx-0 border-radius-5 font-12"
                      >{{ $ml.get('general_view_details') }} <v-icon>{{ panel === 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon></v-btn>
                    </v-flex>

                    <v-flex xs12 md3 text-xs-center v-else>
                      <v-layout row wrap>
                        <v-flex xs6 md12 :px-2="$vuetify.breakpoint.smAndDown">
                          <v-btn 
                            color="primary"
                            outline block
                            class="normalcase graphik-medium mx-0 border-radius-5 white--text"
                            :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                            :disabled="g_isLoading"
                            @click.stop="callNow()"
                          >{{ $ml.get('general_call_now') }}</v-btn>
                        </v-flex>

                        <v-flex xs6 md12 :px-2="$vuetify.breakpoint.smAndDown">
                          <v-btn 
                            color="primary"
                            outline block
                            class="normalcase graphik-medium mx-0 border-radius-5 white--text"
                            :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
                            :disabled="g_isLoading"
                            @click.stop="sendWhatsapp(quote)"
                          >{{ $ml.get('general_send_whatsapp') }}</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </template>
        
        <v-card flat height="100%" class="light-background">
          <v-card-text v-if="quote[policy_type][payment_type].coverages">
            <v-divider />

            <v-layout
              row wrap
              :pa-3="$vuetify.breakpoint.smAndUp"
              :pa-2="$vuetify.breakpoint.xsOnly"
            >
              <v-flex xs5 sm6 pr-1>
                <p
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-10': $vuetify.breakpoint.smAndDown}"
                  class="graphik-medium secondary--text mb-0"
                >{{ $ml.get('fields_coverage') }}</p>
              </v-flex>

              <v-flex xs4 sm3 px-1>
                <p
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-10': $vuetify.breakpoint.smAndDown}"
                  class="graphik-medium secondary--text mb-0"
                >{{ $ml.get('fields_sum_assured') }}</p>

              </v-flex>

              <v-flex xs3 sm3 pl-1>
                <p
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-10': $vuetify.breakpoint.smAndDown}"
                  class="graphik-medium secondary--text mb-0"
                >{{ $ml.get('fields_deductible') }}</p>
              </v-flex>
            </v-layout>

            <v-layout
              row wrap
              :pa-3="$vuetify.breakpoint.smAndUp"
              :pa-2="$vuetify.breakpoint.xsOnly"
              v-for="(coverage, index) in quote[policy_type][payment_type].coverages" :key="index"
              :class="{'white': !(index % 2)}"
            >
              <v-flex xs5 sm6 pr-1>
                <p
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-10': $vuetify.breakpoint.smAndDown}"
                  class="graphik-light dark-grey--text mb-0"
                >{{ coverage.text }}</p>
              </v-flex>

              <v-flex xs4 sm3 px-1>
                <p
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-10': $vuetify.breakpoint.smAndDown}"
                  class="graphik-light dark-grey--text mb-0"
                >{{ coverage.sum_assured | price }}</p>

              </v-flex>

              <v-flex xs3 sm3 pl-1>
                <p
                  :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-10': $vuetify.breakpoint.smAndDown}"
                  class="graphik-light dark-grey--text mb-0"
                >{{ coverage.deductible }}</p>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <quoter-dialog-buy-with-cash :show="dialog_cash" @close="dialog_cash = false" />
    <quoter-dialog-buy-with-cash-axa
      :show="dialog_axa"
      :quote="quote"
      :payment_type="payment_type"
      :policy_type="policy_type"
      @close="dialog_axa = false"
    />
    <quoter-dialog-chubb-offline :show="dialog_chubb" @close="dialog_chubb = false" />
    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  name: 'QuoteResult',
  props: {
    quote: Object,
    title: String,
    payment_type: String,
    policy_type: String,
    quote_data: Object
  },
  components: {
    VueContentLoading,
    'quoter-dialog-buy-with-cash': () => import(/* webpackPrefetch: true */ './QuoterDialogBuyWithCash'),
    'quoter-dialog-buy-with-cash-axa': () => import(/* webpackPrefetch: true */ './QuoterDialogBuyWithCashAxa'),
    'quoter-dialog-chubb-offline': () => import(/* webpackPrefetch: true */ './QuoterDialogChubbOffline')
  },
  data () {
    return {
      panel: null,
      dialog_cash: false,
      dialog_chubb: false,
      dialog_axa: false,
      ribbon: require('@/assets/img/icons/msi/ribbon.png'),
      ribbon_no_fold: require('@/assets/img/icons/msi/ribbon_no_fold.png'),
      show_ribbon: false
    }
  },
  computed: {
    image () {
      return require('@/assets/img/companies/' + this.quote.company + '-full.png')
    }
  },
  methods: {
    getPaymentText () {
      switch (this.payment_type) {
        case '1': return this.$ml.get('quote_result_annual')
        case '2': return this.$ml.get('fields_biannual')
        case '4': return this.$ml.get('fields_quaterly')
        case '12': return this.$ml.get('fields_monthly')
      }
    },
    getCoverageText () {
      switch (this.$route.params.type === 'auto' && this.policy_type) {
        case 'ALTA': return this.$ml.get('fields_amplia')
        case 'MEDIA': return this.$ml.get('fields_limitada')
        case 'BAJA': return this.$ml.get('fields_rc')
      }

      switch (this.$route.params.type === 'hogar' && this.policy_type) {
        case 'ALTA': return this.$ml.get('fields_premium')
        case 'MEDIA': return this.$ml.get('fields_optimo')
        case 'BAJA': return this.$ml.get('fields_basico')
      }
    },
    callNow () {
      this.$analytics('quoter_call_now')
      window.open('tel:5218159801250', '_blank')
    },
    sendWhatsapp (quote) {
      this.$analytics('quoter_send_whatsapp')
      let message = 'Me gustaría recibir más información sobre:%0A%0AAseguradora: ' + this.$ml.get('insurance_' + quote.company) + '%0ACobertura: ' + this.getCoverageText() + '%0AForma de pago: ' + this.getPaymentText() + '%0AID de cotización: ' + this.quote_data.uuid
      window.open('https://api.whatsapp.com/send?phone=5218127251196&text=' + message, '_blank')
    },
    buyWithCash (quote) {
      this.g_isLoading = true
      this.$analytics('quoter_select_buy_with_cash_gnp')
      let payload = {
        quote_uuid: quote[this.policy_type][this.payment_type].quote_uuid,
        product_uuid: quote[this.policy_type][this.payment_type].product_uuid,
        payment: this.payment_type,
        first_payment: quote[this.policy_type][this.payment_type].prices.first,
        next_payment: quote[this.policy_type][this.payment_type].prices.next
      }
      this.$api.buyWithCash(
        payload,
        () => {
          this.g_isLoading = false
          this.$nextTick(() => {
            this.dialog_cash = true
          })
        },
        response => this.$errorHandling(response).then(() => {
          this.buyWithCash(quote)
        }, () => {})
      )
    },
    open (quote, payWithCash) {
      if (quote.company === 'chubb' || quote.company === 'chubbhogar-1' || quote.company === 'chubbhogar-2' || quote.company === 'chubbhogar-3' || quote.company === 'chubbhogar-4') {
        this.g_isLoading = true
        this.$api.validateDowntime(
          quote.company,
          response => {
            this.g_isLoading = false
            if (response.body.data.service_down) {
              this.$analytics('quoter_chubb_downtime')
              this.dialog_chubb = true
            } else if (payWithCash) {
              this.$goTo('/emitir/' + this.$route.params.type + '/' + this.$route.params.id + '/' + quote[this.policy_type][this.payment_type].product_uuid + '/' + quote[this.policy_type][this.payment_type].frequency + '/' + quote[this.policy_type][this.payment_type].quote_price_uuid + '?cash=true', 'quoter_select_pay_with_cash')
            } else {
              this.$goTo('/emitir/' + this.$route.params.type + '/' + this.$route.params.id + '/' + quote[this.policy_type][this.payment_type].product_uuid + '/' + quote[this.policy_type][this.payment_type].frequency + '/' + quote[this.policy_type][this.payment_type].quote_price_uuid, 'quoter_select_pay_with_creditcard')
            }
          },
          response => this.$errorHandling(response).then(() => {
            this.open(quote)
          }, () => {})
        )
      } else if (payWithCash) {
        if (quote.company === 'gnp') {
          this.buyWithCash(quote)
        } else if (quote.company === 'axa') {
          this.dialog_axa = true
        } else {
          this.$goTo('/emitir/' + this.$route.params.type + '/' + this.$route.params.id + '/' + quote[this.policy_type][this.payment_type].product_uuid + '/' + quote[this.policy_type][this.payment_type].frequency + '/' + quote[this.policy_type][this.payment_type].quote_price_uuid + '?cash=true', 'quoter_select_pay_with_cash')
        }
      } else {
        this.$goTo('/emitir/' + this.$route.params.type + '/' + this.$route.params.id + '/' + quote[this.policy_type][this.payment_type].product_uuid + '/' + quote[this.policy_type][this.payment_type].frequency + '/' + quote[this.policy_type][this.payment_type].quote_price_uuid, 'quoter_select_pay_with_creditcard')
      }
    }
  }
}
</script>

<style scoped>
  .rotate-180 {
    -webkit-transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -o-transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
  }

  .ribbon, .ribbon-no-fold {
    position: absolute !important;
  }

  /***********/
  /* xsOnly */
  /***********/
  @media screen and (max-width: 600px) {
    .ribbon {
      height: 80px !important;
      width: 80px !important;
      left: -6px !important;
      top: -6px !important;
    }

    .ribbon-no-fold {
      height: 80px !important;
      width: 80px !important;
      top: -1px !important;
    }
  }

  /***********/
  /* smAndUp */
  /***********/
  @media screen and (min-width: 600px) {
    .ribbon {
      height: 100px !important;
      width: 100px !important;
      left: -8px !important;
      top: -8px !important;
    }

    .ribbon-no-fold {
      height: 120px !important;
      width: 120px !important;
      top: -1px !important;
    }
  }

  /***********/
  /* lgAndUp */
  /***********/
  @media screen and (min-width: 1050px) {
    .ribbon {
      height: 120px !important;
      width: 120px !important;
      left: -8px !important;
      top: -11px !important;
    }

  }
</style>
